<template>
  <div class="home">
    <div class="shopro-form-contaner">
      <van-form>
        <van-field
          v-model="name"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
        ></van-field>
        <van-field
          v-model="phone"
          type="number"
          name="手机号"
          label="手机号"
          placeholder="请输入手机号"
        ></van-field>
      </van-form>
    </div>
    <div class="shopro-submit-containerd" v-if="hideshowreq">
      <div class="shopro-query-button" @click="submitForm">查询我的预约</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      name: "",
      phone: "",
      hideshowreq: true,
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight,
      count: 2,//判断是否需要隐藏按钮
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
        this.count = 1;
      })();
    };
  },
  methods: {
    submitForm() {
      let that = this;
      if (this.name != "" && this.phone != "") {
        that.$axios
          .get(
            "https://huanghe.xprings.com/api/apply/search?name=" +
              that.name +
              "&phone=" +
              that.phone
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.code == 0) {
              that.$router.push({
                path: "/error",
                query: { message: res.data.msg, from: "req" },
              });
            } else {
              that.$router.push({
                path: "/success",
                query: { message: res.data },
              });
            }
          });
      } else {
        Toast("请填写查询数据");
      }
    },
  },
  watch: {
    showHeight: function () {
      if (this.count == 2) {
        if (this.docmHeight > this.showHeight) {
          this.hideshowreq = false;
        } else {
          this.hideshowreq = true;
        }
      }
      if (this.count == 1) {
        this.hideshowreq = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shopro-form-contaner {
  padding: 0 14px;
}

.van-field__label {
  width: 3rem;
}

.van-cell__value {
  color: #c4c4c4;
}

.shopro-submit-containerd {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.shopro-query-button {
  width: auto;
  height: 43px;
  line-height: 43px;
  text-align: center;
  box-shadow: 0px 7px 11px 2px rgba(255, 168, 0, 0.34);
  border-radius: 43px;
  font-size: 14px;
  margin: 0 14px;
  margin-top: 12px;
  box-shadow: none;
  border: 1px solid #ff9800;
  background: #fff;
  color: #ff9600;
}
</style>
